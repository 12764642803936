<template>
    <div class="wrapper">
        <admin-title :title="$route.meta.name"></admin-title>
        <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
            <el-form-item prop="account" label="账号">
                <el-input size="small" v-model="formData.account" placeholder="账号"></el-input>
            </el-form-item>
            <el-form-item label="手机号" prop="username">
                <el-input size="small" v-model="formData.username" placeholder="姓名"></el-input>
            </el-form-item>
            <el-form-item label="性别" prop="mobile">
                <dict-select :dict-key="DICT_SYSTEM_SEX" @updateDictValue="updateSex"></dict-select>
            </el-form-item>
            <el-form-item prop="roleId" label="角色">
                <el-select size="small" v-model="formData.roleId" clearable placeholder="请选择">
                    <el-option v-for="item in roleList" :key="item.id" :label="item.roleName"
                               :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="部门" prop="mobile">
                <el-select size="small" v-model="model" placeholder="">
                    <el-option v-for="item in options" :key="item.value" :label="item.label"
                               :value="item.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="岗位" prop="mobile">
                <el-select size="small" v-model="model" placeholder="">
                    <el-option v-for="item in options" :key="item.value" :label="item.label"
                               :value="item.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item v-if="!$route.params.id" label="密码" prop="password">
                <el-input size="small" v-model="formData.password" placeholder="密码"></el-input>
            </el-form-item>

            <!-- <el-form-item label="状态">
              <el-switch v-model="formData.status" :active-value="1" :inactive-value="0" active-color="#409eff" inactive-color="#dcdfe6"></el-switch>
            </el-form-item> -->
            <el-form-item label=" ">
                <el-button size="small" type="" @click="cancel">取消</el-button>
                <el-button size="small" type="primary" @click="save">保存</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import {addAdmin, editAdmin, manageUserDetailApi, getRoleList, manageUserListApi} from './api'
import {DICT_SYSTEM_SEX} from '@/enum/dict'

export default {
    name: 'Detail',

    data() {
        return {
            options: [],
            DICT_SYSTEM_SEX,

            formData: {
                account: '',
                username: '',
                phone: '',
                sex: 0,
                avatar: '',
                status: 20,
                is_super: 20,
                role_ids: []
            },


            rules: {
                userName: [{required: true, message: '姓名不能为空', trigger: 'blur'}],
                password: [
                    {required: true, message: '密码不能为空', trigger: 'blur'},
                    {
                        pattern: /^(?=.*[A-Z])(?=.*[0-9])[A-Z|a-z|0-9|_]{8,18}$/,
                        message: '密码为8~18位，可包含大小写字母、数字和下划线，且至少包含大写字母+数字',
                        trigger: 'blur'
                    }
                ],
                mobile: [{required: true, message: '手机号不能为空', trigger: 'blur'}],
                roleId: [{required: true, message: '请选择角色', trigger: 'blur'}]
            },
            roleList: [],
            roleProps: {
                children: 'children',
                label: 'name',
                value: 'id',
                emitPath: false,
                multiple: true
            }
        }
    },

    mounted() {
        this.getRoleList()
        if (this.$route.params.id) this.getAdminDetail()
    },

    methods: {

        updateSex(val) {
            this.formData.sex = val
        },

        save() {
            this.$refs.form.validate(async valid => {
                if (valid) {
                    if (this.formData.id) {
                        // 编辑
                        await editAdmin(this.formData).then(() => {
                            this.$message.success('编辑成功')
                            this.$router.back()
                        })
                    } else {
                        // 新增
                        await addAdmin(this.formData).then(() => {
                            this.$message.success('添加成功')
                            this.$router.back()
                        })
                    }
                } else {
                    return false
                }
            })
        },
        async getAdminDetail() {
            this.formData = await manageUserDetailApi(this.$route.params.id)
        },
        cancel() {
            this.$router.back(-1)
        },
        async getRoleList() {
            const res = await getRoleList({pageSize: 50, page: 1})
            this.roleList = res.list || []
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .el-form /deep/ {
    .el-form-item {
      .el-form-item__content {
        width: 500px;

        .el-cascader,
        .el-select {
          width: 100%;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.el-form /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>
