import { http } from '@/http/axios.js'

// 管理员列表
export function manageUserListApi(params) {
  return http({
    url: `/company/basic/mangeUser/list`,
    method: 'get',
    params
  })
}
// 新增管理员
export function addAdmin(data) {
  return http({
    url: `/admin/user/add`,
    method: 'post',
    data
  })
}
// 编辑管理员
export function editAdmin(data) {
  return http({
    url: `/admin/user/edit`,
    method: 'post',
    data
  })
}
// 删除管理员
export function removeAdmin(data) {
  return http({
    url: `/admin/user/delete`,
    method: 'post',
    data
  })
}
// 管理员详情
export function manageUserDetailApi(id) {
  return http({
    url: `/company/basic/mangeUser/detail`,
    method: 'get',
    params: {id}
  })
}
// 角色列表
export function getRoleList(params) {
  return http({
    url: '/admin/role/page',
    method: 'GET',
    params
  })
}
// 状态
export function changeStatus(data) {
  return http({
    url: '/admin/user/status',
    method: 'post',
    data
  })
}
